// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import {
  // Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextFieldProps,
  FormHelperText,
} from '@mui/material';
import React, { useState } from 'react';

// ----------------------------------------------------------------------

type KeyValue = {
  key: string | number;
  value: string | number;
  label?: string;
};

type IProps = {
  name: string;
  defaultValue?: string[] | number[];
  options: KeyValue[];
};

type Props = IProps & TextFieldProps;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RHFMultiselect({ name, defaultValue, options, ...other }: Props) {
  const [selectedValue, setselectedValue] = useState<any[]>(defaultValue || []); // Pass in default value from parent component
  const { control, register } = useFormContext();
  const { label, sx } = other;

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setselectedValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const lookup: any = {};
  options.forEach((option) => (lookup[option.value] = option.label || option.value));

  return (
    <FormControl fullWidth sx={sx}>
      <InputLabel id="RHFMultiselect-label">{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <div>
            <Select
              {...field}
              fullWidth
              labelId="RHFMultiselect-label"
              label={label}
              multiple
              {...register(`${name}` as const)}
              value={selectedValue}
              renderValue={(selected) => selected.map((s) => lookup[s]).join(', ')}
              error={!!error}
              displayEmpty
              onChange={handleChange}
              MenuProps={MenuProps}
            >
              {options.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  <Checkbox checked={selectedValue.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label || option.value} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          </div>
        )}
      />
    </FormControl>
  );
}
