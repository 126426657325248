import { useEffect } from 'react';
// next
import { useRouter } from 'next/router';
// hooks
import useAuth from '@common/hooks/useAuth';

import { DefaultGuardProps } from '@common/@types/guards';

import LoadingScreen from '@common/components/LoadingScreen';

import useSiteConfig from '@common/hooks/useSiteConfig';
import { Role } from 'common/@types/auth';

// ----------------------------------------------------------------------

export default function SiteConfigGuard({ children }: DefaultGuardProps) {
  const { push } = useRouter();

  const { user, logout } = useAuth();

  const { data: siteConfig, isLoading } = useSiteConfig();

  function render404() {
    push('/dashboard/daily-activity/?error=campus_site_block');
    logout();
  }

  useEffect(() => {
    // If it's an invalid copy of the site; 404
    if (siteConfig === null) {
      render404();
    } else if (user && siteConfig?.campuses && siteConfig?.campuses.length > 0) {
      // Admins can see everything
      if (user?.alphaAuthAttributes?.role === Role.ADMIN) {
        return;
      }

      // Otherwise, if the user has a specific campus ID, make sure they can see this site
      if (
        user?.alphaAuthAttributes?.campusId &&
        user.alphaAuthAttributes.campusId !== '%' &&
        user.alphaAuthAttributes.campusId !== '>=0'
      ) {
        // If the user has a campus ID, make sure it's valid
        const userCampus = siteConfig.campuses
          .map((c) => parseInt(c))
          .find((campus) => campus === parseInt(user.alphaAuthAttributes.campusId));

        // If it's not, 404
        if (userCampus === undefined || userCampus === null) {
          render404();
        }
      }
    }
  }, [siteConfig, isLoading, user]);

  // If it's undefined, effectively; `null` is the "we couldn't oad anything"
  // value; `undefined` is the "load hasn't finished yet" signifier
  if (!siteConfig && siteConfig !== null) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
