// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_USER = '/user';
const ROOTS_APPS = '/apps';
const ROOTS_LEARNING_SCIENCE = '/learning-science';
const ROOTS_LEARN = '/learn';
const ROOTS_CHECK_CHART = '/check-chart';
const ROOTS_COACHING = '/coaching';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  resetSent: path(ROOTS_AUTH, '/reset-sent'),
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_USER = {
  root: ROOTS_USER,
  general: {
    root: path(ROOTS_USER, '/'),
    new: path(ROOTS_USER, '/new'),
    list: path(ROOTS_USER, '/list'),
    account: path(ROOTS_USER, '/account'),
    edit: (name: string) => path(ROOTS_USER, `/${name}/edit`),
  },
};

export const PATH_APPS = {
  root: ROOTS_APPS,
  general: {
    root: path(ROOTS_APPS, '/'),
    my_apps: path(ROOTS_APPS, '/my-apps'),
  },
};

export const PATH_LEARNING_SCIENCE = {
  root: ROOTS_LEARNING_SCIENCE,
  general: {
    root: path(ROOTS_LEARNING_SCIENCE, '/'),
    snapshot: path(ROOTS_LEARNING_SCIENCE, '/snapshot'),
    map: path(ROOTS_LEARNING_SCIENCE, '/map'),
    panorama: path(ROOTS_LEARNING_SCIENCE, '/panorama'),
    gaps: path(ROOTS_LEARNING_SCIENCE, '/gaps'),
    learning_report: path(ROOTS_LEARNING_SCIENCE, '/learning-report'),
    goals: path(ROOTS_LEARNING_SCIENCE, '/goals'),
  },
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    week: path(ROOTS_DASHBOARD, '/week'),
    session: path(ROOTS_DASHBOARD, '/session'),
    career: path(ROOTS_DASHBOARD, '/career'),
    leaderboards: {
      units_mastered: path(ROOTS_DASHBOARD, '/leaderboards/units-mastered'),
      sessions_2hr: path(ROOTS_DASHBOARD, '/leaderboards/leaderboard-2hr-sessions'),
      beast_of_the_week: path(ROOTS_DASHBOARD, '/leaderboards/beast-of-the-week'),
      speedrun_competition: path(ROOTS_DASHBOARD, '/leaderboards/speedrun-competition'),
      timeback: path(ROOTS_DASHBOARD, '/timeback'),
      learning_blitz: path(ROOTS_DASHBOARD, '/leaderboards/learning-blitz'),
      learning_to_give: path(ROOTS_DASHBOARD, '/leaderboards/learn-to-give'),
      // AI-GEN START - JB AI Assistant - GPT4
      math_facts_challenge: path(ROOTS_DASHBOARD, '/leaderboards/math-facts-challenge'),
      // AI-GEN END
      alpha_coin: path(ROOTS_DASHBOARD, '/leaderboards/alpha-coin'), // AI-GEN - Cursor - GPT4
    },
    daily_activity: {
      day: path(ROOTS_DASHBOARD, '/daily-activity/'),
      week: path(ROOTS_DASHBOARD, '/daily-activity/week'),
      month: path(ROOTS_DASHBOARD, '/daily-activity/month'),
    },
    learn_to_earn: path(ROOTS_DASHBOARD, '/learn-to-earn'),
    accountability: path(ROOTS_DASHBOARD, '/accountability'),
  },
};

export const PATH_LEARN = {
  root: ROOTS_LEARN,
  general: {
    recommendations: path(ROOTS_LEARN, '/recommendations'),
  },
};

export const PATH_ADMIN = {
  root: '/admin',
  general: {
    app_completion_tracker: path('/admin', '/app-completion-tracker'),
    ixl_diagnostic: path('/admin', '/ixl-diagnostic'),
    l4_mastery: path('/admin', '/l4-mastery'),
    realtime_minutes: path('/admin', '/realtime-minutes'),
    student_mastery: path('/admin', '/student-mastery'),
    survey_results: path('/admin', '/survey-results'),
  },
};

export const PATH_GUIDE = {
  root: '/guide',
  general: {
    recommendations_submit: path('/guide', '/recommendations-submit'),
    test_submit: path('/guide', '/test-submit'),
  },
};

export const PATH_CHECK_CHART = {
  root: ROOTS_CHECK_CHART, // View page for students/parents, list page for guides/admin
  general: {
    edit: path(ROOTS_CHECK_CHART, '/edit'), // Edit page for guides/admin
    order: path(ROOTS_CHECK_CHART, '/edit/order'), // Order page for guides/admin
    student: path(ROOTS_CHECK_CHART, '/student'), // Student view page for guides/admin
  },
};

export const PATH_COACHING = {
  root: ROOTS_COACHING,
  general: {
    index: path(ROOTS_COACHING, '/summaries/'),
    week: path(ROOTS_COACHING, '/summaries/week'),
  },
};
