import { useEffect, useState } from 'react';
// next
import { useRouter } from 'next/router';
// hooks
import useAuth from '@common/hooks/useAuth';
import Login from '../pages/auth/login';
// components
import LoadingScreen from '@common/components/LoadingScreen';

import { DefaultGuardProps } from '@common/@types/guards';

// ----------------------------------------------------------------------

export default function AuthGuard({ children }: DefaultGuardProps) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  const { pathname, push } = useRouter();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  useEffect(() => {
    // Always ensure we're on the URL for the daily activity page if we're looking at
    // the Login screen. This ensures we don't need to add a ton of possible redirect_url
    // values, at the expense of annoying the user a _little_ bit by not bringing them
    // back to the page they requested.
    if (isInitialized && !isAuthenticated) {
      push('/dashboard/daily-activity/');
      return;
    }

    // Redirect if the user's campus ID is '009' and the current domain is not 'dash.learnandearn.school' on prod
    if (
      process.env.NEXT_PUBLIC_ENV === 'prod' &&
      ['4', '9'].includes(user?.alphaAuthAttributes?.campusId ?? '') &&
      window.location.hostname !== 'dash.learnandearn.school'
    ) {
      // Redirect to dash.learnandearn.school
      push('https://dash.learnandearn.school');
      return;
    }
  }, [isInitialized, isAuthenticated, user]);

  useEffect(() => {
    if (requestedLocation && pathname !== requestedLocation) {
      push(requestedLocation);
    }
    if (isAuthenticated) {
      setRequestedLocation(null);
    }
  }, [isAuthenticated, pathname, requestedLocation]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  return <>{children}</>;
}
