// routes
import { PATH_DASHBOARD, PATH_USER } from './paths';
import { blockUserNotInRoles } from './NavConfig';
import { Role } from '@common/@types/auth';

export const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: PATH_DASHBOARD.general.daily_activity.day,
  },
  {
    label: 'Profile',
    linkTo: PATH_USER.general.account,
  },
  {
    label: 'User List',
    linkTo: PATH_USER.general.list,
    blackListFunction: blockUserNotInRoles(Role.ADMIN), // Restricting only to ADMIN role
  },
];
