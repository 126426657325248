// next
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Stack, Typography } from '@mui/material';
// routes
// hooks
import useResponsive from '@common/hooks/useResponsive';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
// guards
import GuestGuard from '../../guards/GuestGuard';
// components
import Page from '@common/components/Page';
import Logo from '@common/components/Logo';
import Image from '@common/components/Image';
import Iconify from '@common/components/Iconify';
// sections
import { LoginForm } from '../../sections/auth/login';

// Google login bits
import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
import Amplify from '@aws-amplify/core';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { mixpanelService } from '@common/analytics/mixpanel';
import useSiteConfig from 'common/hooks/useSiteConfig';
import { amplitudeService, getCampusTypeByDomain } from 'common/analytics/amplitude';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const searchParams = useSearchParams();
  const error = searchParams.get('error');
  const router = useRouter();

  const [errorState, setErrorState] = useState<string | null>(null);
  const mdUp = useResponsive('up', 'md');

  const siteConfig = useSiteConfig();
  const showGoogleButton = siteConfig?.data?.ssoEnabled;
  const ssoDomain = siteConfig?.data?.domain;
  let googleButtonText = `Login with Google ${ssoDomain ? `(@${ssoDomain})` : ''}`.trim();
  if (ssoDomain === 'learnandearn.school') {
    googleButtonText = 'Login with Google (@2hourlearning.com)';
  }

  const loginIllustration = siteConfig?.data?.themeOptions?.images?.login;

  const startOAuth = async (): Promise<void> => {
    try {
      mixpanelService.track('Login Click', { using: 'App via SSO' });
      if (siteConfig.data?.domain != null)
        amplitudeService.trackClickLogin(
          getCampusTypeByDomain(siteConfig.data?.domain),
          'Google SSO'
        );

      // We're leaving the site, so we wanna reconfigure Amplify quickly to use the refresh
      // token flow. We can't configure it for multiple at the same time, and our usual
      // default value is user/pass. We only need these two set for two milliseconds before
      // we leave the site, so that `federatedSignIn` below works as intended.
      Amplify.configure({
        Auth: {
          authenticationFlowType: 'REFRESH_TOKEN_AUTH',
          federationTarget: 'COGNITO_USER_POOLS',
        },
      });

      // Trigger the signin
      Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });

      // Then, once we've set that, _reset_ it back so that if the user hits the back button,
      // everything still works with user/pass auth. It's annoying. I know.
      Amplify.configure({
        Auth: {
          authenticationFlowType: 'USER_PASSWORD_AUTH',
        },
      });
    } catch (error) {
      console.error(error);
      setErrorState(error.message);
    }
  };

  const checkForError = async () => {
    // Check for URL error
    if (error === 'campus_site_block') {
      setErrorState(`Sorry, you are not authorized to use Dash on ${window.location.hostname}.`);
      router.replace('/dashboard/daily-activity/', undefined, { shallow: true });
      return;
    }

    // Check for dev error
    const cognitoDomainURL = process.env.NEXT_PUBLIC_COGNITO_DOMAIN_URL;
    if (!cognitoDomainURL || !cognitoDomainURL.startsWith('https')) {
      console.error(
        'Dash is misconfigured! NEXT_PUBLIC_COGNITO_DOMAIN_URL environment variable was not set before "export" was run.'
      );
      setErrorState(
        'There is an issue with the Dash website. If you know your username and password, you can still sign in below. If you need help, please ask your Guide.'
      );
      return;
    }

    // Check for issues with the login
    let errorMessage;
    const user = await Auth.currentAuthenticatedUser();
    const errorClaim = user.signInUserSession.idToken.payload['custom:error'];
    if (errorClaim) {
      // Specifically "no Dash user found that corresponded to the Google user"
      if (errorClaim === 'no-matching-user') {
        errorMessage =
          'Sorry! Your email address has not been linked to Dash yet. If you know your username and password, you can sign in below. If you need help, please ask your Guide.';
      } else {
        errorMessage =
          'If you know your username and password, you can sign in below. If you need help, please ask your Guide.';
      }

      setErrorState(errorMessage);
      sessionStorage.setItem('errorState', JSON.stringify(errorMessage));
      await Auth.signOut();
    }
  };

  useEffect(() => {
    const errorStateFromStorage = sessionStorage.getItem('errorState');
    if (errorStateFromStorage) {
      setErrorState(JSON.parse(errorStateFromStorage));
      sessionStorage.removeItem('errorState');
    }
    checkForError();
  }, [error]);

  return (
    <GuestGuard>
      <Page title="Login">
        <RootStyle>
          <HeaderStyle>
            <Logo />
          </HeaderStyle>

          {mdUp && (
            <SectionStyle>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                Hi, Welcome Back
              </Typography>
              {loginIllustration && (
                <Image visibleByDefault disabledEffect src={loginIllustration} alt="login" />
              )}
            </SectionStyle>
          )}

          <Container maxWidth="sm">
            <ContentStyle>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    Sign in to Dash
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    Enter your details below.
                  </Typography>
                </Box>
                <Box>
                  <Logo />
                </Box>
              </Stack>

              {errorState && (
                <Typography sx={{ fontWeight: 'bold', marginBottom: '24px' }} color="error">
                  {errorState}
                </Typography>
              )}

              <LoginForm />

              {/* Left outside the LoginForm because that's tightly coupled with Yup */}
              {showGoogleButton ? (
                <LoadingButton
                  fullWidth
                  sx={{ marginTop: '12px', textTransform: 'initial' }}
                  color="secondary"
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={
                    false /* could have startOAuth set to true, but causes issues when the user hits the back button */
                  }
                  onClick={startOAuth}
                >
                  <Iconify
                    icon="devicon:google"
                    width={24}
                    height={24}
                    sx={{ marginRight: '12px' }}
                  />
                  {googleButtonText}
                </LoadingButton>
              ) : null}
            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>
    </GuestGuard>
  );
}
