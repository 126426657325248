import PageNoMobile from '../pages/no-mobile';

import { DefaultGuardProps } from '@common/@types/guards';

function isMobile() {
  return window.innerWidth <= 750;
}

export default function MobileGuard({ children }: DefaultGuardProps) {
  if (isMobile()) {
    return <PageNoMobile />;
  }
  return <>{children}</>;
}
