// next
import { useRouter } from 'next/router';
// routes
import { PATH_ADMIN, PATH_GUIDE, PATH_USER } from '../routes/paths';
import BlacklistedAttributeGuard from './BlacklistedAttributeGuard';
import { Role } from '@common/@types/auth';
import { blockUserNotInRoles } from '../routes/NavConfig';

import { DefaultGuardProps } from '@common/@types/guards';
// ----------------------------------------------------------------------

const pageAccessByRole: Record<string, Role[]> = {
  // Admin pages
  [PATH_ADMIN.general.app_completion_tracker]: [Role.ADMIN, Role.GUIDE],
  [PATH_ADMIN.general.ixl_diagnostic]: [Role.ADMIN, Role.GUIDE],
  [PATH_ADMIN.general.l4_mastery]: [Role.ADMIN, Role.GUIDE],
  [PATH_ADMIN.general.realtime_minutes]: [Role.ADMIN, Role.GUIDE],
  [PATH_ADMIN.general.student_mastery]: [Role.ADMIN, Role.GUIDE],
  [PATH_ADMIN.general.survey_results]: [Role.ADMIN, Role.GUIDE],
  // Guide Pages
  [PATH_GUIDE.general.recommendations_submit]: [Role.ADMIN, Role.GUIDE],
  //User Pages
  [PATH_USER.root]: [Role.ADMIN],
  [PATH_USER.general.list]: [Role.ADMIN],
  [PATH_USER.general.new]: [Role.ADMIN],
  [PATH_USER.general.edit('[name]')]: [Role.ADMIN],
};
const PATH_USER_NAME_EDIT_REGEX = /\/user\/.+\/edit/;

export default function PageGuard({ children }: DefaultGuardProps) {
  const router = useRouter();

  let blacklistFunction = undefined;
  if (pageAccessByRole[router.pathname] != null) {
    blacklistFunction = blockUserNotInRoles(...pageAccessByRole[router.pathname]);
  } else if (PATH_USER_NAME_EDIT_REGEX.test(router.pathname)) {
    blacklistFunction = blockUserNotInRoles(Role.ADMIN);
  }

  return (
    <BlacklistedAttributeGuard hasContent blacklistFunction={blacklistFunction}>
      {children}
    </BlacklistedAttributeGuard>
  );
}
